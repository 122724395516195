/* *{
    font-family: Arial, Helvetica, sans-serif;
} */
/* body{
    position: relative;
    display: flex; 
} */
.browser-modal {
  position: absolute;
  /* opacity: 0;
    visibility: hidden;
    display: none;  */
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;

  background-color: rgba(0, 0, 0, 0.457);
}

.browser-container ,.resume-browser-container{
  /* position: absolute; */
  position: relative;
  /* top: 5%; */
  /* left: 0; */

  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  width: 360px;
  max-width: 460px;
  height: 315px;
  /* min-height: fit-content; */
  /* border: 1px solid rgba(127, 127, 127, 0.493); */
  border-radius: 5px 5px 5px 5px;
  margin: auto;
  box-shadow: -5px 0px 10px 5px rgba(0, 0, 0, 0.322);
  border: 1px solid #757575;
  z-index:20;
  background-color: #fff;
  overflow: hidden;
}

.resume-browser-container{
  position:'relative';
  height: 255px;
  min-height: fit-content;
  width: 300px;
  max-width: 400px;
  /* width:auto; */
  background-color: #000;
}
.browser-header {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: rgb(221, 210, 162);
  color: grey;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 0.5px solid #75757521;
  height: 1.1rem;
  z-index: 4;
  text-align: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
}
.browser-button-cls {
  display: flex;
  position: absolute;
  left: 0.25rem;
  top: 0.125rem;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: #f66969;
  padding: 0.125em;
  cursor: pointer;
  font-family: futura bold;
  align-items: center;
  text-align: center;
  /* align-content: center; */
  justify-content: center;
  margin: 0;
  z-index: 910;
}
#browser-drag-tag {
  width: 150px;
  height: 1.5em;
  position: absolute;
  top: 7px;
}
/* .x-btn-text:before{
    content:'x';
} */
.term-button-cls:hover ,.browser-button-cls:hover {
  /* transform: translateX(1px) translateY(2px); */
  color: #fff;
  box-shadow: 0px 0px 3px 1px #4c4c4c6c;
  /* box-shadow: 1px 1px 2px 1px #000; */
  /* box-shadow: -6px 0px 5px -5px rgba(0, 0, 0, 0.498); */
  /* background-color: #d15858; */
}
.doc-combo {
  /* box-shadow: -6px 0px 5px -5px rgba(0, 0, 0, 0.498),inset -8px -6px 15px -10px rgba(0, 0, 0, 0.498); */
  box-shadow: -6px 0px 5px -5px rgba(0, 0, 0, 0.498),
    inset -10px -4px 10px -10px rgba(0, 0, 0, 0.498),
    inset 10px 4px 10px -5px rgb(0 0 0 / 20%);
}
.browser-button-cls :first-child {
  visibility: none;
}
/* .browser-button-cls :hover{
    .browser-button-cls :first-child{
      visibility: visible;
    }
} */
#browser-favicon {
  position: absolute;
  left: 0.45em;
  top: 0.35em;
  width: 10px;
  height: auto;
  border-radius: 100%;
  opacity: 1;
}
.browser-title {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: left;
  left: 50px;
  bottom: -0.1rem;
  /* font-size: 12px; */
  height: 1rem;
  min-width: 100px;
  /* width: 100%; */
  /* padding: 0.25rem; */
  font-size:0.6rem ;
  
  /* float: center; */
  /* margin: auto; */
  background-color: rgb(233, 232, 228);
  border-radius:5px 5px  0px 0px;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* box-shadow: 0px 0px 15px -10px rgba(28,28,28,0.62); */
  z-index: 2;
}
.browser-nav-bar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  /* align-content: flex-start; */
  width: 100%;
  height: 9%;
  font-size: 0.75rem;
  background-color: rgb(233, 232, 228);
  box-shadow: 0px 4px 15px 2px rgba(173, 173, 173, 0.411);
  z-index: 3;
  border-bottom: 1px solid #75757521;
}
.nav-btn:hover {
  cursor: pointer;
  color: #fff;
}
.browser-header img {
  width: 100%;
  opacity: 0.1;
}
.url-box {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 50%;
  color:#616060;
  background-color: #fff;
  border-radius: 20px;
  text-align: left;
  /* margin: 0 auto; */
  margin-top: 5px;
  /* margin-left: 5px; */
}
.url-arrows {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 25%;
  /* height: 40px; */
  font-size: 1.25rem;
  font-family: futura;
  color: #868686;
  /* border: 1px solid blue; */
  justify-content: center;
  align-items: center;
  align-content: center;
  /* text-align: center; */
}
.url-box text {
  /* padding-left: 20px; */
  align-items: center;
  color: #757575;
}
.url-box img:hover {
  background-color: rgb(255, 252, 104);
  border: 2px solid rgb(255, 215, 38);
}
.url-box img {
  position: absolute;
  /* top: -21px; */
  left: 1px;
  width: 15px;
  height: auto;
  opacity: 1;
  cursor: pointer;
  border-radius: 20px;
  background-color: #fff;
  border: 2px solid rgb(100, 253, 17);
}
.show-browser-info-alert {
  visibility: visible;
  opacity: 1;
  display: inherit;
}
.browser-info-alert {
  display: flex;
  position: absolute;
  width: fit-content;
  position: relative;
  align-items: center;

  top: 20px;
  left: -75px;
  font-size: 0.75em;
  height: 15px;
  padding: 0.25em 1em;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(111, 255, 0);
  border-radius: 5px;
  z-index: 3000;
  color: #000;
  cursor: pointer;
  white-space: nowrap;
  /* opacity: 0;
    visibility: hidden;
    display: none; */
}
.browser-info-alert:hover {
  color: rgb(18, 18, 18);
  background-color: rgba(115, 255, 87, 0.712);
}
#browser-url{
  position:absolute;
  left: 1.2rem;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;  
  text-overflow: ellipsis;
  padding:0rem 0.5rem;
  cursor: pointer;
}
.browser-body {
  position: relative;
  display: flex;
  /* justify-content: center;
  align-content: center; */
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 0px 0px 5px 5px;
  z-index: 1;
  /* overflow: hidden; */
  padding:  0.25rem;
  overflow: scroll;
  /* padding: .5em; */
}

.proj-tag {
  width: 100%;
  height: 100%;
  color: magenta;
  font-size: 12px;
  /* border:  2px solid yellow; */
}

.browser-body p {
  color: rgb(0, 255, 55);
  /* border:  2px solid yellow; */
  font-size: 11px;
}

.browser-body-content {
  width: 100%;
  height: fit-content;
  /* padding: 0.5em; */
  margin: auto;
  border:'5px solid blue';
}

.frame-container {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100vw;
  margin: auto;
  min-width: fit-content;
  min-height: fit-content;
  overflow: hidden;
}
.projectFrame img {
  margin: auto;
}
/* .projectFrame img{
    Width: 50%;
    height: auto;
} */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: none;
  /* background: url('./assets/loading-bar.gif') center center no-repeat; */
  /* background: url('./assets/Blue_loading_circle.gif') center center no-repeat; */
  border-radius: 0px 0px 10px 10px;
  border-bottom: none;
  /* display:flex; */
  /* justify-content: center; */
  /* border: 1px solid red; */
}
/* .responsive-iframe img{
    justify-content: center;
} */
iframe {
  padding: 0.5px;
}
.browser-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#copyright-browser {
  padding-right: 1em;
}
.bottom-adr {
  display: flex;
  flex-direction: row;
  position: relative;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  height: 10px;
  width: fit-content;
  background-color: rgb(221, 210, 162);
  padding: 0.5em;
  margin-top: 0.5em;
  border-radius: 0px 10px 0px 10px;
  box-shadow: 0px 5px 10px 5px #75757594;
}
.browser-poweredBy {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #868686;
}
